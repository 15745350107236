// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import FinancingDepositMoneyHandlers from '../handlers/financing-deposit-money.handlers';
import DepositMoneyErrorHandlers from '../handlers/financing-deposit-money-error.handlers';
import DepositMoneyErrorTrackingHandlers from '../handlers/financing-deposit-money-error.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingDepositMoneyHookType } from './types/financing-deposit-money.hook.type';
import { DepositMoneyFetchErrorEnumeration } from 'enumerations/deposit-money-fetch-error.enumeration';
import { DepositMoneySectionsType } from '../types/financing-deposit-money-sections.type';
import { FinancingDepositMoneyViewType } from '../types/financing-deposit-money.view.type';

const FinancingDepositMoneyHook = ({
  error: wrapperError = null,
}: FinancingDepositMoneyViewType): FinancingDepositMoneyHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<DepositMoneyFetchErrorEnumeration | null>(wrapperError);
  const [issueBank, setIssueBank] = useState<string>('');
  const [maxTransferLimit, setMaxTransferLimit] = useState<number>(0);
  const [sections, setSections] = useState<DepositMoneySectionsType[]>([]);
  const [virtualIban, setVirtualIban] = useState<string>('');

  const { currentCard } = AppContextConsumerHook(),
    { limit } = currentCard?.creditDetails || {},
    { id: cardId } = currentCard;
  const { formatMessage } = useCbIntl();
  const { handleLoadDepositMoney, handleSetOutProvidingErrorEvent } = FinancingDepositMoneyHandlers(
    {
      cardId,
      setIssueBank,
      setMaxTransferLimit,
      setSections,
      setVirtualIban,
      setFetching,
      setError,
      wrapperError,
    }
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleLoadDepositMoney();
  }, []);

  useEffect(() => {
    handleSetOutProvidingErrorEvent();
  }, [wrapperError]);

  return {
    fetching,
    formatMessage,
    error,
    issueBank,
    limit,
    maxTransferLimit,
    sections,
    virtualIban,
    ...DepositMoneyErrorHandlers(AppTrackingHook(DepositMoneyErrorTrackingHandlers)),
  };
};

export default FinancingDepositMoneyHook;
