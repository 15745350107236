// Vendors
import React from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import { KycExpiredModalComponent } from './components/expired-kyc-modal/expired-kyc-modal.component';
import CreditLimitIncreaseSidebarComponent from './components/sidebar/credit-limit-increase-sidebar.component';
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import {
  getCreditLimitIncreaseConfiguration,
  getFetchErrorConfiguration,
} from './configurations/credit-limit-increase.configuration';
// Constants
import constants from './constants/credit-limit-increase.constants';
// Contexts
import { CreditLimitIncreaseContextConsumerHOC } from './contexts/credit-limit-increase.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Hooks
import CreditLimitIncreaseContainerHook from './hooks/credit-limit-increase.view.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { CreditLimitIncreaseStyled } from './credit-limit-increase.view.styled';

const FinancingCreditLimitIncreaseBaseView = (): React.ReactElement => {
  const { onCancelHybridFlow } = MobileConnectorContextConsumerHook();
  const navigate = useNavigate();
  const {
    isMobileOrTablet,
    creditLimitIncreaseContext,
    isSuccessTax,
    defaultResult,
    formatMessage,
    handleGoToFinancingTracking,
  } = CreditLimitIncreaseContainerHook();
  const { showExpiredFiocModal, wizardStatus } = creditLimitIncreaseContext;
  const { fetching, error, currentStep } = wizardStatus;

  return (
    <CreditLimitIncreaseStyled {...{ isMobileOrTablet }}>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <FetchErrorComponent
            {...getFetchErrorConfiguration({
              formatMessage,
              onCancelHybridFlow,
              navigate,
              fetching,
              error,
            })}
          >
            <WizardComponent
              {...getCreditLimitIncreaseConfiguration({
                isSuccessTax,
                defaultResult,
                creditLimitIncreaseContext,
                onCancelHybridFlow,
                navigate,
                handleGoToFinancingTracking,
              })}
              currentStep={currentStep}
            />
          </FetchErrorComponent>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled {...constants.RIGHT_COLUMN_PROPS}>
          <CreditLimitIncreaseSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
      {showExpiredFiocModal && <KycExpiredModalComponent />}
    </CreditLimitIncreaseStyled>
  );
};
const FinancingCreditLimitIncreaseView = CreditLimitIncreaseContextConsumerHOC(
  FinancingCreditLimitIncreaseBaseView
);

export { FinancingCreditLimitIncreaseView };
