// Types
import { NextStepErrorCreditLimitIncreasePropsHandlerType } from './types/next-step-error-credit-limit-increase-props.component.handler.type';
import { NextStepErrorCreditLimitIncreaseReturnHandlerType } from './types/next-step-error-credit-limit-increase-return.component.handler.type';
// Utils
import { navigateToFinancing } from 'containers/financing/views/credit-limit-increase/utils/navigate-to-financing.utils';

const onClickBackButtonHandler = ({
  onCancelHybridFlow,
  navigate,
  handleClickBackButtonTracking,
}: NextStepErrorCreditLimitIncreasePropsHandlerType): void => {
  handleClickBackButtonTracking();
  navigateToFinancing({ onCancelHybridFlow, navigate });
};

const NextStepErrorCreditLimitIncreaseHandler = (
  props: NextStepErrorCreditLimitIncreasePropsHandlerType
): NextStepErrorCreditLimitIncreaseReturnHandlerType => ({
  handleOnClickBackButton: () => onClickBackButtonHandler(props),
});

export default NextStepErrorCreditLimitIncreaseHandler;
