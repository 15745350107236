// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import { useFinancingContextConsumerHook } from 'containers/financing/contexts/financing.context';
// Handlers
import { InitHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-0-init.handler';
// Types
import { ExitModalHookType } from './types/exit-modal.hook.type';
import { Step5CreditLimitIncreaseHookType } from '../components/step5/hooks/types/step-5-credit-limit-increase.component.hook.type';
// Utils
import { navigateToFinancing } from 'containers/financing/views/credit-limit-increase/utils/navigate-to-financing.utils';

const ExitModalHook = (
  handleEvaluateNewOffer?: Step5CreditLimitIncreaseHookType['handleEvaluateNewOffer']
): ExitModalHookType => {
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [disabledButtonsCancelModal, setDisabledButtonsCancelModal] = useState(false);
  const { currentCard } = AppContextConsumerHook(),
  { id: cardId } = currentCard || {};
  const { onCancelHybridFlow } = MobileConnectorContextConsumerHook();
  const navigate = useNavigate();
  const { showModal, setShowModal } = useFinancingContextConsumerHook();
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { handleCancelProcessClick } = InitHandlers({ cardId, creditLimitContext });

  useEffect(
    () => () => {
      setShowModal(false);
      setIsRejectModal(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (showModal === false && isRejectModal) {
        setIsRejectModal(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showModal]
  );

  return {
    isRejectModal,
    disabledButtonsCancelModal,
    showModal,
    setIsRejectModal,
    setShowModal,
    cancelModalButtonClick: () =>
      isRejectModal
        ? handleEvaluateNewOffer?.()
        : navigateToFinancing({ onCancelHybridFlow, navigate }),
    cancelModalLinkClick: () =>
      isRejectModal
        ? setShowModal(false)
        : handleCancelProcessClick({ setDisabledButtonsCancelModal }),
  };
};

export default ExitModalHook;
