// Vendors
import React from 'react';
// Components
import {
  AssetComponent,
  ButtonComponent,
  LoaderComponent,
  MessageComponent,
  ParagraphComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import { CloseButtonComponent } from './components/close/close-button.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants, {
  cancelUrl,
} from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
import constantsStep4 from './constants/step-4-account-aggregation.constants';
// Enumerations
import { BankStatusEnumeration } from './enumerations/bank-status.enumeration';
// Hooks
import ExitModalHook from 'containers/financing/views/credit-limit-increase/hooks/exit-modal.hook';
import Step4AccountAggregationHook from './hooks/step-4-account-aggregation.component.hook';
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  MessageComponentHeaderStyled,
  Step4AccountAggregationBanksTableStyled,
  Step4AccountAggregationDataRowsStyled,
  Step4AccountAggregationGoToUploadDocumentationStyled,
  Step4AccountAggregationRowSectionStyled,
  Step4AccountAggregationRowStyled,
  Step4AccountAggregationStyled,
  Step4AccountAggregationTextSectionStyled,
  Step4AccountAggregationTextStyled,
} from './step-4-account-aggregation.component.styled';
// Translations
import { translationsOtherSteps } from 'containers/financing/views/credit-limit-increase/translations/exit-modal-credit-limit-increase.component.translations';
import { step4AccountAggregationTranslations } from './translations/step-4-account-aggregation.translations';
// Utils
import { hasValidBankData } from './utils/step-4-account-aggregation.utils';

const Step4AccountAggregationComponent = (): React.ReactElement => {
  const {
    disabledButtonsCancelModal,
    showModal,
    setShowModal,
    cancelModalButtonClick,
    cancelModalLinkClick,
  } = ExitModalHook();
  const {
    bankData,
    showInvalidBankMessage,
    isLoadingGoToUploadDocument,
    handleCloseInvalidBankMessage,
    onClickAddBankButton,
    onClickGoToUploadDocumentation,
    onAccountAggregationGoNext,
  } = Step4AccountAggregationHook();
  const { formatMessage } = useCbIntl();

  return (
    <Step4AccountAggregationStyled>
      <Step4AccountAggregationTextSectionStyled>
        <TitleComponent>
          {formatMessage({ id: step4AccountAggregationTranslations.SUBTITLE })}
        </TitleComponent>
        <ParagraphComponent>
          {formatMessage({ id: step4AccountAggregationTranslations.SMALL_SUBTITLE })}
        </ParagraphComponent>
      </Step4AccountAggregationTextSectionStyled>
      <ButtonComponent {...constantsStep4.ADD_BANK_BUTTON} onClick={onClickAddBankButton}>
        {formatMessage({ id: step4AccountAggregationTranslations.ADD_BANK })}
      </ButtonComponent>

      <Step4AccountAggregationBanksTableStyled>
        <Step4AccountAggregationRowStyled {...constantsStep4.BANK_HEADER_PROPS}>
          <Step4AccountAggregationRowSectionStyled>
            {formatMessage({ id: step4AccountAggregationTranslations.ADDED_BANKS })}
          </Step4AccountAggregationRowSectionStyled>
          <Step4AccountAggregationRowSectionStyled>
            {formatMessage({ id: step4AccountAggregationTranslations.STATUS })}
          </Step4AccountAggregationRowSectionStyled>
        </Step4AccountAggregationRowStyled>
        <Step4AccountAggregationDataRowsStyled>
          {bankData.map(bank => (
            <Step4AccountAggregationRowStyled
              {...constantsStep4.BANK_BODY_PROPS}
              key={bank.userBankId}
            >
              <Step4AccountAggregationRowSectionStyled>
                {bank.bankName}
              </Step4AccountAggregationRowSectionStyled>
              <Step4AccountAggregationRowSectionStyled>
                {bank.status === BankStatusEnumeration.LOADING && (
                  <LoaderComponent {...constantsStep4.LOADER_PROPS} />
                )}
                {bank.status === BankStatusEnumeration.OK && (
                  <AssetComponent {...constantsStep4.SUCCESS_PROPS} />
                )}
                {bank.status === BankStatusEnumeration.KO && (
                  <AssetComponent {...constantsStep4.ERROR_PROPS} />
                )}
                <Step4AccountAggregationTextStyled
                  isError={bank.status === BankStatusEnumeration.KO}
                >
                  {formatMessage({ id: step4AccountAggregationTranslations[bank.status] })}
                </Step4AccountAggregationTextStyled>
              </Step4AccountAggregationRowSectionStyled>
            </Step4AccountAggregationRowStyled>
          ))}
        </Step4AccountAggregationDataRowsStyled>
      </Step4AccountAggregationBanksTableStyled>

      {showInvalidBankMessage && (
        <MessageComponent {...constantsStep4.MESSAGE_ERROR_PROPS}>
          <MessageComponentHeaderStyled>
            <ParagraphComponent bold>
              {formatMessage({ id: step4AccountAggregationTranslations.INVALID_BANK })}
            </ParagraphComponent>
            <CloseButtonComponent handleCloseButton={handleCloseInvalidBankMessage} />
          </MessageComponentHeaderStyled>
          <ParagraphComponent>
            {formatMessage(
              { id: step4AccountAggregationTranslations.INVALID_BANK_DESCRIPTION },
              { br: <br /> }
            )}
          </ParagraphComponent>
          <Step4AccountAggregationGoToUploadDocumentationStyled>
            <ParagraphComponent>
              {formatMessage({
                id: step4AccountAggregationTranslations.INVALID_BANK_DESCRIPTION_2,
              })}
            </ParagraphComponent>
            <ButtonComponent
              {...constantsStep4.GO_TO_UPLOAD_DOCUMENTATION_BUTTON}
              disabled={isLoadingGoToUploadDocument}
              loading={isLoadingGoToUploadDocument}
              onClick={onClickGoToUploadDocumentation}
            >
              {formatMessage({
                id: step4AccountAggregationTranslations.GO_TO_UPLOAD_DOCUMENTATION,
              })}
            </ButtonComponent>
          </Step4AccountAggregationGoToUploadDocumentationStyled>
        </MessageComponent>
      )}

      <WizardStepFooterComponent
        rightButtonConfiguration={{
          onClick: onAccountAggregationGoNext,
          disabled: !hasValidBankData(bankData),
        }}
        cancelModal={{
          disabledButtons: disabledButtonsCancelModal,
          cancelUrl,
          showModal,
          setShowModal,
          clicks: {
            linkClick: cancelModalLinkClick,
            buttonClick: cancelModalButtonClick,
          },
          translations: {
            TITLE: translationsOtherSteps.TITLE,
            DESCRIPTION_01: translationsOtherSteps.DESCRIPTION,
            VALUESDESCRIPTION_01: { days: constants.EXIT_MODAL_DAYS.FIFTEEN },
            LINK: translationsOtherSteps.LINK_TEXT,
            BUTTON: translationsOtherSteps.PRIMARY_BUTTON_TEXT,
          },
        }}
      />
    </Step4AccountAggregationStyled>
  );
};

export { Step4AccountAggregationComponent };
