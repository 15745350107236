// Constants
import { ACCEPTED_202 } from './constants/step-4-account-aggregation.handler.constants';
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
// Enumerations
import { BankStatusEnumeration } from '../components/step4/account-aggregation/enumerations/bank-status.enumeration';
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Resolvers
import {
  accountAggregationGoNextResolver,
  accountAggregationResolver,
  accountAggregationExtendTokenResolver,
} from 'containers/financing/views/credit-limit-increase/resolvers/step-4-account-aggregation.resolvers';
import { backToPreviousStepResolver } from '../resolvers/step-0-init.resolvers';
import { selectMethodResolver } from '../resolvers/step-3-select-method.resolvers';
// Translations
import { step4AccountAggregationTranslations } from 'containers/financing/views/credit-limit-increase/components/step4/account-aggregation/translations/step-4-account-aggregation.translations';
// Types
import { AccountAggregationHandlersPropsType } from './types/step-4-account-aggregation-props.handler.type';
import { AccountAggregationHandlersReturnType } from './types/step-4-account-aggregation-return.handler.type';
import { SaveTinkDataFromParamsType } from './types/save-tink-data-from-params.type';
import { SendAccountAggregationRequestType } from './types/send-account-aggregation-request.type';
import { TinkDataFromTinkType } from './types/tink-data-params.type';
// Utils
import {
  hasAccountVerificationAndTransaction,
  mapParamsToCamelCase,
  mapStep4Data,
  recursiveInitToGetBankData,
  setKOInNewBankAggregation,
} from './utils/step-4-account-aggregation.utils';
import { isIosWebView } from '@gruposantander/web-ui-framework';

const saveTinkDataFromParamsHandler = ({
  creditLimitContext,
  searchParams,
  formatMessage,
  setSearchParams,
}: SaveTinkDataFromParamsType): void => {
  const { step4, setStep4 } = creditLimitContext;

  if (searchParams?.size && (searchParams.get('userId') || searchParams.get('userid'))) {
    const tinkData = {} as TinkDataFromTinkType;

    searchParams.forEach((value, key) => (tinkData[mapParamsToCamelCase(key)] = value));
    const newBankAccount = {
      bankName: formatMessage({ id: step4AccountAggregationTranslations.ADDING_NEW_BANK }),
      status: BankStatusEnumeration.LOADING,
      userId: tinkData.userid,
    };

    setStep4({
      ...step4,
      tinkData,
      stepData: {
        ...step4.stepData,
        bankData: [newBankAccount, ...(step4.stepData?.bankData || [])],
      },
    });
    setSearchParams();
  } else {
    setSearchParams();
  }
};

const sendAccountAggregationRequestHandler = async ({
  creditLimitContext,
  cardId,
}: SendAccountAggregationRequestType): Promise<void> => {
  const { step4, setStep4, wizardStatus } = creditLimitContext;
  const { caseId } = wizardStatus;

  if (!step4.tinkData) return;
  if (
    !hasAccountVerificationAndTransaction(
      step4.tinkData.accountVerificationReportId,
      step4.tinkData.transactionReportId
    )
  ) {
    return setKOInNewBankAggregation({ step4, setStep4 });
  }

  const [response, error, status] = await accountAggregationResolver({
    caseId,
    tinkData: mapStep4Data(step4.tinkData),
  });

  if (error) {
    return setKOInNewBankAggregation({ step4, setStep4 });
  }

  if (response) {
    if (status === ACCEPTED_202) {
      await recursiveInitToGetBankData({
        cardId,
        dateOfFirstTime: new Date(),
        step4,
        setStep4,
      });
    } else {
      setStep4({ ...step4, tinkData: undefined, stepData: response.stepData });
    }
  }
};
/* eslint-disable complexity */
const onClickAddBankButton = async ({
  isHybrid,
  handleOpenExternalUrlOnHybridFlow,
  handleOpenWebViewBrowser,
  aggregationUrl,
}: Pick<
  AccountAggregationHandlersPropsType,
  'isHybrid' | 'handleOpenExternalUrlOnHybridFlow' | 'handleOpenWebViewBrowser'
> & {
  aggregationUrl: string | undefined;
}): Promise<void> => {
  await accountAggregationExtendTokenResolver();

  if (aggregationUrl) {
    try {
      isHybrid
        ? isIosWebView()
          ? handleOpenWebViewBrowser && handleOpenWebViewBrowser(aggregationUrl)
          : handleOpenExternalUrlOnHybridFlow && handleOpenExternalUrlOnHybridFlow(aggregationUrl)
        : window.location.replace(aggregationUrl);
    } catch {
      window.location.replace(aggregationUrl);
    }
  }
};

const onClickGoToUploadDocumentation = async ({
  cardId,
  creditLimitContext,
  setCurrentStep,
  setIsLoadingGoToUploadDocument,
}: Pick<
  AccountAggregationHandlersPropsType,
  'cardId' | 'creditLimitContext' | 'setCurrentStep' | 'setIsLoadingGoToUploadDocument'
>) => {
  const { step4, setStep4, wizardStatus, setWizardStatus } = creditLimitContext;
  const { caseId } = wizardStatus;

  setIsLoadingGoToUploadDocument(true);

  const [response, error] = await backToPreviousStepResolver({
    cardId,
    caseId,
    currentStep: wizardStatus.currentStepInText,
  });

  if (error) {
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }

  if (response) {
    const [response2, error2] = await selectMethodResolver({ caseId, isUploadDocumentation: true });

    if (error2) {
      setWizardStatus({ ...wizardStatus, fetching: false, error: true });
      return;
    }

    if (response2) {
      const currentStep = StepEnumToIndexMap[response2.nextStep];
      const currentStepInText = response2.nextStep as CreditLimitIncreaseStepsEnumeration;

      setStep4({
        ...step4,
        stepData: response2.stepData,
      });
      setWizardStatus({
        ...wizardStatus,
        caseId: response2.caseId,
        currentStep,
        currentStepInText,
      });
      setCurrentStep(currentStep);
      setIsLoadingGoToUploadDocument(false);
    }
  }
};

const accountAggregationGoNextHandler = async ({
  creditLimitContext,
  setCurrentStep,
}: Pick<
  AccountAggregationHandlersPropsType,
  'creditLimitContext' | 'setCurrentStep'
>): Promise<void> => {
  const { step4, setStep4, step5, setStep5, wizardStatus, setWizardStatus } = creditLimitContext;
  const { caseId } = wizardStatus;

  setStep4({
    ...step4,
    isLoadingNextRequest: true,
  });

  const [response, error] = await accountAggregationGoNextResolver(caseId);

  if (error) {
    setStep4({
      ...step4,
      isLoadingNextRequest: false,
    });
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }
  if (response) {
    const currentStep = StepEnumToIndexMap[response.nextStep];
    const currentStepInText = response.nextStep as CreditLimitIncreaseStepsEnumeration;

    setStep5({
      ...step5,
      ...response.stepData,
    });
    setWizardStatus({ ...wizardStatus, caseId: response.caseId, currentStep, currentStepInText });
    setCurrentStep(currentStep);
  }
};

const showInvalidBankMessageHandler = ({
  creditLimitContext,
  searchParams,
  setShowInvalidBankMessage,
}: Pick<
  AccountAggregationHandlersPropsType,
  'creditLimitContext' | 'searchParams' | 'setShowInvalidBankMessage'
>): void => {
  const { stepData } = creditLimitContext.step4;

  if (
    searchParams.size === 0 &&
    stepData?.bankData?.length &&
    stepData.bankData.find(bankData => bankData.status === BankStatusEnumeration.KO) &&
    !stepData.bankData.find(
      bankData =>
        bankData.status === BankStatusEnumeration.OK ||
        bankData.status === BankStatusEnumeration.LOADING
    )
  ) {
    setShowInvalidBankMessage(true);
  } else {
    setShowInvalidBankMessage(false);
  }
};

const AccountAggregationHandlers = ({
  cardId,
  creditLimitContext,
  isHybrid,
  handleOpenWebViewBrowser,
  handleOpenExternalUrlOnHybridFlow,
  searchParams,
  formatMessage,
  setSearchParams,
  setCurrentStep,
  setShowInvalidBankMessage,
  setIsLoadingGoToUploadDocument,
}: AccountAggregationHandlersPropsType): AccountAggregationHandlersReturnType => ({
  handleSaveTinkDataFromParams: () =>
    saveTinkDataFromParamsHandler({
      creditLimitContext,
      searchParams,
      formatMessage,
      setSearchParams,
    }),
  handleSendAccountAggregationRequest: () =>
    sendAccountAggregationRequestHandler({ cardId, creditLimitContext }),
  handleOnClickAddBankButton: (aggregationUrl: string | undefined) =>
    onClickAddBankButton({
      isHybrid,
      handleOpenWebViewBrowser,
      handleOpenExternalUrlOnHybridFlow,
      aggregationUrl,
    }),
  handleOnClickGoToUploadDocumentation: () =>
    onClickGoToUploadDocumentation({
      cardId,
      creditLimitContext,
      setCurrentStep,
      setIsLoadingGoToUploadDocument,
    }),
  handleAccountAggregationGoNext: () => {
    accountAggregationGoNextHandler({ creditLimitContext, setCurrentStep });
  },
  handleShowInvalidBankMessage: () =>
    showInvalidBankMessageHandler({ setShowInvalidBankMessage, creditLimitContext, searchParams }),
});

export default AccountAggregationHandlers;
