// Vendors
import React from 'react';
// Components
import {
  AssetComponent,
  ConfirmationNextStepsComponent,
  LinkComponent,
  LoaderComponent,
  ParagraphComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Configurations
import { getConfirmationNextStepsConfiguration } from './configurations/step-3-credit-limit-increase.configuration';
// Constants
import constants, {
  cancelUrl,
} from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
import constantsStep3 from './constants/step-3-credit-limit-increase.constants';
import { PUBLIC_URL_PRIVACY_POLICY } from 'constants/po-editor.constants';
// Hooks
import ExitModalHook from 'containers/financing/views/credit-limit-increase/hooks/exit-modal.hook';
import Step3CreditLimitIncreaseHook from './hooks/step-3-credit-limit-increase.component.hook';
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  ConfirmationNextStepsComponentStyled,
  ParagraphComponentStyled,
  Step3CreditLimitIncreaseDisplayRow2Styled,
  Step3CreditLimitIncreaseDisplayRowStyled,
  Step3CreditLimitIncreaseImageAndTextsStyled,
  Step3CreditLimitIncreaseSeparatorStyled,
  Step3CreditLimitIncreaseStyled,
  Step3CreditLimitIncreaseTextSectionStyled,
  Step3CreditLimitIncreaseUploadDocumentationStyled,
} from './step-3-credit-limit-increase.component.styled';
// Translations
import translations from './translations/step-3-credit-limit-increase.translations';
import { translationsOtherSteps } from '../../translations/exit-modal-credit-limit-increase.component.translations';
// Utilities
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';
import { getConfigurationValue } from 'utils/get-configuration-value';

const Step3CreditLimitIncreaseComponent = (): React.ReactElement => {
  const {
    disabledButtonsCancelModal,
    showModal,
    setShowModal,
    cancelModalButtonClick,
    cancelModalLinkClick,
  } = ExitModalHook();
  const {
    isLoadingNextRequest,
    isLoadingUploadDocumentationRequest,
    handleSelectAccountAggregation,
    handleSelectUploadDocumentation,
    handleOpenExternalUrlOnHybridFlow,
  } = Step3CreditLimitIncreaseHook();
  const { formatMessage } = useCbIntl();

  return (
    <Step3CreditLimitIncreaseStyled>
      <Step3CreditLimitIncreaseTextSectionStyled>
        <TitleComponent>{formatMessage({ id: translations.SUBTITLE })}</TitleComponent>
        <ParagraphComponent>
          {formatMessage({ id: translations.SMALL_SUBTITLE })}
        </ParagraphComponent>
      </Step3CreditLimitIncreaseTextSectionStyled>
      <TitleComponent>{formatMessage({ id: translations.HOW_DOES_IT_WORK })}</TitleComponent>
      <Step3CreditLimitIncreaseDisplayRowStyled>
        <Step3CreditLimitIncreaseImageAndTextsStyled>
          <AssetComponent {...constantsStep3.ASSET_1_PROPS} />
          <Step3CreditLimitIncreaseTextSectionStyled>
            <ParagraphComponent bold>
              {formatMessage({ id: translations.TITLE_HOW_DOES_IT_WORK_1 })}
            </ParagraphComponent>
            <ParagraphComponent>
              {formatMessage({ id: translations.DESCRIPTION_HOW_DOES_IT_WORK_1 })}
            </ParagraphComponent>
          </Step3CreditLimitIncreaseTextSectionStyled>
        </Step3CreditLimitIncreaseImageAndTextsStyled>
        <Step3CreditLimitIncreaseImageAndTextsStyled>
          <AssetComponent {...constantsStep3.ASSET_2_PROPS} />
          <Step3CreditLimitIncreaseTextSectionStyled>
            <ParagraphComponent bold>
              {formatMessage({ id: translations.TITLE_HOW_DOES_IT_WORK_2 })}
            </ParagraphComponent>
            <ParagraphComponent>
              {formatMessage({ id: translations.DESCRIPTION_HOW_DOES_IT_WORK_2 })}
            </ParagraphComponent>
          </Step3CreditLimitIncreaseTextSectionStyled>
        </Step3CreditLimitIncreaseImageAndTextsStyled>
        <Step3CreditLimitIncreaseImageAndTextsStyled>
          <AssetComponent {...constantsStep3.ASSET_3_PROPS} />
          <Step3CreditLimitIncreaseTextSectionStyled>
            <ParagraphComponent bold>
              {formatMessage({ id: translations.TITLE_HOW_DOES_IT_WORK_3 })}
            </ParagraphComponent>
            <ParagraphComponent>
              {formatMessage({ id: translations.DESCRIPTION_HOW_DOES_IT_WORK_3 })}
            </ParagraphComponent>
          </Step3CreditLimitIncreaseTextSectionStyled>
        </Step3CreditLimitIncreaseImageAndTextsStyled>
      </Step3CreditLimitIncreaseDisplayRowStyled>
      <ParagraphComponentStyled>
        <ParagraphComponent>
          {formatMessage({ id: translations.HOW_DOES_IT_WORK_INFORMATION })}
        </ParagraphComponent>
      </ParagraphComponentStyled>
      <Step3CreditLimitIncreaseSeparatorStyled />

      <ConfirmationNextStepsComponentStyled>
        <ConfirmationNextStepsComponent {...getConfirmationNextStepsConfiguration(formatMessage)} />
      </ConfirmationNextStepsComponentStyled>
      <Step3CreditLimitIncreaseSeparatorStyled />

      <Step3CreditLimitIncreaseUploadDocumentationStyled>
        <ParagraphComponent>
          {formatMessage({ id: translations.UPLOAD_DOCUMENTATION_DESCRIPTION })}
        </ParagraphComponent>
        <Step3CreditLimitIncreaseDisplayRow2Styled>
          <LinkComponent
            disabled={isLoadingUploadDocumentationRequest}
            onClick={handleSelectUploadDocumentation}
            assetConfiguration={constantsStep3.ASSET_DOCUMENTATION_PROPS}
          >
            {formatMessage({ id: translations.UPLOAD_DOCUMENTATION })}
          </LinkComponent>
          {isLoadingUploadDocumentationRequest && (
            <LoaderComponent sizes={constantsStep3.LOADER_SIZES} />
          )}
        </Step3CreditLimitIncreaseDisplayRow2Styled>
      </Step3CreditLimitIncreaseUploadDocumentationStyled>
      <ParagraphComponent>
        {formatMessage({ id: translations.PRIVACY_POLICY_TEXT }, {
          privacityUrl: chunk =>
            makeChunkLinkIntlUtil({
              chunk,
              onClick: () =>
                handleOpenExternalUrlOnHybridFlow(getConfigurationValue(PUBLIC_URL_PRIVACY_POLICY)),
            }),
        } as any)}
      </ParagraphComponent>

      <WizardStepFooterComponent
        isFooterDisabled={isLoadingNextRequest || isLoadingUploadDocumentationRequest}
        rightButtonConfiguration={{
          onClick: handleSelectAccountAggregation,
          loading: isLoadingNextRequest,
        }}
        cancelModal={{
          cancelUrl,
          disabledButtons: disabledButtonsCancelModal,
          showModal,
          setShowModal,
          clicks: {
            buttonClick: cancelModalButtonClick,
            linkClick: cancelModalLinkClick,
          },
          translations: {
            TITLE: translationsOtherSteps.TITLE,
            DESCRIPTION_01: translationsOtherSteps.DESCRIPTION,
            VALUESDESCRIPTION_01: { days: constants.EXIT_MODAL_DAYS.FIFTEEN },
            LINK: translationsOtherSteps.LINK_TEXT,
            BUTTON: translationsOtherSteps.PRIMARY_BUTTON_TEXT,
          },
        }}
      />
    </Step3CreditLimitIncreaseStyled>
  );
};

export default Step3CreditLimitIncreaseComponent;
