// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { PathsCommonEnum } from '@openbank/cf-ui-static-data';
// Types
import { RouteType } from 'types/react-router-dom/route-configuration.type';
// Views
import { FinancingPaymentMethodView } from 'containers/financing/views/payment-method/financing-payment-method.view';
import { FinancingLandingView } from 'containers/financing/views/landing/financing-landing.view';
import { FinancingFastCashView } from 'containers/financing/views/fast-cash/financing-fast-cash.view';
import { FinancingEarlyRepaymentView } from 'containers/financing/views/early-repayment/financing-early-repayment.view';
import { FinancingPPIView } from 'containers/financing/views/ppi/financing-ppi.view';
import { FinancingDepositMoneyView } from 'containers/financing/views/deposit-money/financing-deposit-money.view';
import { FinancingCreditLimitModifyBaseView } from 'containers/financing/views/credit-limit-modify/credit-limit-modify.view';

const EPP_ID_ROUTE = ':eppId/',
  FINANCING_COMMON_ROUTER_ROUTES: RouteType[] = [
    {
      element: FinancingEarlyRepaymentView,
      props: { path: `${EPP_ID_ROUTE}${RoutesEnumeration.EARLY_REPAYMENT}` },
    },
    { element: FinancingPaymentMethodView, props: { path: RoutesEnumeration.PAYMENT_METHOD } },
    { element: FinancingFastCashView, props: { path: RoutesEnumeration.FAST_CASH } },
    {
      element: FinancingPPIView,
      props: { path: `${RoutesEnumeration.PPI}${PathsCommonEnum.ROOT}${PathsCommonEnum.ANY}` },
    },
    { element: FinancingDepositMoneyView, props: { path: RoutesEnumeration.DEPOSIT_MONEY } },
    {
      element: FinancingCreditLimitModifyBaseView,
      props: { path: RoutesEnumeration.MODIFY_LIMIT },
    },
    { element: FinancingLandingView, props: { index: true } },
  ];

export { FINANCING_COMMON_ROUTER_ROUTES };
