// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_STEP_FOOTER_PROPS } from './constants/financing-fast-cash-second-step-actions.component';
// Hooks
import FinancingFastCashSecondStepActionsHook from './hooks/financing-fast-cash-second-step-actions.hook';
// Types
import { FinancingFastCashSecondStepActionsType } from './types/financing-fast-cash-second-step-actions.type';
// Translations
import { STEPS } from 'containers/financing/views/fast-cash/translations/financing-fast-cash.translations';

const FinancingFastCashSecondStepActionsComponent = ({
  handleRetryChallengeError,
}: FinancingFastCashSecondStepActionsType): React.ReactElement => {
  const { challenge, handleNextButtonClick } = FinancingFastCashSecondStepActionsHook({
    handleRetryChallengeError,
  });

  return (
    <WizardStepFooterComponent
      {...WIZARD_STEP_FOOTER_PROPS}
      rightButtonConfiguration={{
        onClick: handleNextButtonClick,
        disabled: !challenge,
        children: <FormattedMessageComponent id={STEPS.SECOND.NEXT} />,
      }}
    />
  );
};

export default FinancingFastCashSecondStepActionsComponent;
