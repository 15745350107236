// Constants
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
// Resolvers
import {
  approvalResolver,
  evaluateNewOfferResolver,
  rejectM2Resolver,
} from 'containers/financing/views/credit-limit-increase/resolvers/step-5-feedback.resolvers';
import { getPPIInfoResolver } from 'containers/financing/views/credit-limit-increase/resolvers/step-0-init.resolvers';
// Types
import { CreditLimitIncreaseContextType } from '../contexts/types/credit-limit-increase.context.type';
import { EvaluateNewOfferType } from './types/step-5-evaluate-new-offer.handler.type';
import { FeedbackPropsType } from './types/step-5-credit-limit-increase-props.handler.type';
import { FeedbackReturnType } from './types/step-5-credit-limit-increase-return.handler.type';
// Utils
import { navigateToFinancing } from '../utils/navigate-to-financing.utils';
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';

const step5GoNextHandler = async ({
  creditLimitContext,
  setCurrentStep,
}: Pick<FeedbackPropsType, 'creditLimitContext' | 'setCurrentStep'>) => {
  const { wizardStatus, setWizardStatus, step5, setStep5, step6, setStep6 } = creditLimitContext;
  const { caseId } = wizardStatus;

  setStep5({
    ...step5,
    isLoadingNextRequest: true,
  });

  const [result, error] = await approvalResolver(caseId);

  if (error) {
    setStep5({
      ...step5,
      isLoadingNextRequest: false,
    });
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }
  if (result) {
    const currentStep = StepEnumToIndexMap[result.nextStep];
    const currentStepInText = result.nextStep;

    setStep6({
      ...step6,
      stepData: result?.stepData,
    });
    setWizardStatus({
      ...wizardStatus,
      caseId: result.caseId.toString(),
      currentStep,
      currentStepInText,
    });
    setCurrentStep(StepEnumToIndexMap[result.nextStep]);
  }
};

// eslint-disable-next-line complexity
const evaluateNewOfferHandler = async ({
  creditLimitContext,
  acceptance,
  setCurrentStep,
  handleClickRejectOfferTracking,
  handleClickGoToSignContractTracking,
}: EvaluateNewOfferType) => {
  const { wizardStatus } = creditLimitContext;
  const { caseId } = wizardStatus;

  if (acceptance) {
    handleClickGoToSignContractTracking();
    creditLimitContext.setStep5({
      ...creditLimitContext.step5,
      isLoadingGoToSignContractRequest: true,
    });
  } else {
    handleClickRejectOfferTracking();
    creditLimitContext.setStep5({ ...creditLimitContext.step5, isLoadingRejectOfferRequest: true });
  }

  const [result, error] = await evaluateNewOfferResolver({
    newAmount: acceptance ? creditLimitContext.step5.newCreditLimit : undefined,
    acceptance,
    caseId,
  });

  let changes = {};
  if (error) {
    if (acceptance) {
      changes = { hasGoToSignContractRequestFailed: true, isLoadingGoToSignContractRequest: false };
    } else {
      changes = { hasRejectOfferRequestFailed: true, isLoadingRejectOfferRequest: false };
    }
    creditLimitContext.setStep5({ ...creditLimitContext.step5, ...changes });
    return;
  }
  if (result) {
    const currentStep = StepEnumToIndexMap[result.nextStep];
    const currentStepInText = result.nextStep;

    if (acceptance) {
      changes = {
        hasGoToSignContractRequestFailed: false,
      };
    } else {
      changes = { hasRejectOfferRequestFailed: false };
    }
    creditLimitContext.setStep5({ ...creditLimitContext.step5, ...result.stepData, ...changes });
    creditLimitContext.setWizardStatus({
      ...creditLimitContext.wizardStatus,
      caseId: result.caseId.toString(),
      currentStep,
      currentStepInText,
    });
    setCurrentStep(StepEnumToIndexMap[result.nextStep]);
  }
};

const getPPIInfoHandler = async ({
  ppiStatus,
  creditLimitContext,
}: Pick<FeedbackPropsType, 'ppiStatus' | 'creditLimitContext'>): Promise<void> => {
  if (ppiStatus) {
    return;
  }

  const { wizardStatus, setWizardStatus, step5, setStep5 } = creditLimitContext;
  setWizardStatus({
    ...wizardStatus,
    fetching: true,
    error: false,
  });

  const [response, error] = await getPPIInfoResolver();

  if (error || !response) {
    setWizardStatus({
      ...wizardStatus,
      fetching: false,
      error: false,
    });
    return;
  }

  setStep5({ ...step5, ppiStatus: response.status });

  setWizardStatus({
    ...wizardStatus,
    fetching: false,
    error: false,
  });
};

const rejectM2Handler = async (
  creditLimitContext: CreditLimitIncreaseContextType
): Promise<void> => {
  const { wizardStatus } = creditLimitContext;
  const { caseId, currentStepInText } = wizardStatus;

  if (currentStepInText === CreditLimitIncreaseStepsEnumeration.REJECT_M2) {
    await rejectM2Resolver(caseId);
  }
};

const FeedbackHandlers = ({
  ppiStatus,
  creditLimitContext,
  onCancelHybridFlow,
  navigate,
  setCurrentStep,
  handleClickGoToFinancingTracking,
  handleClickGoToSignContractTracking,
  handleClickRejectOfferTracking,
}: FeedbackPropsType): FeedbackReturnType => ({
  handleStep5GoNext: () =>
    step5GoNextHandler({
      creditLimitContext,
      setCurrentStep,
    }),
  handleGoToFinancing: () =>
    navigateToFinancing({
      onCancelHybridFlow,
      navigate,
      tracking: handleClickGoToFinancingTracking,
    }),
  handleEvaluateNewOffer: acceptance =>
    evaluateNewOfferHandler({
      creditLimitContext,
      acceptance: Boolean(acceptance),
      setCurrentStep,
      handleClickRejectOfferTracking,
      handleClickGoToSignContractTracking,
    }),
  handleGetPPIInfo: () => getPPIInfoHandler({ ppiStatus, creditLimitContext }),
  handleRejectM2: () => rejectM2Handler(creditLimitContext),
});

export default FeedbackHandlers;
