// Vendors
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import {
  approvalService,
  evaluateNewOfferService,
  rejectM2Service,
} from '../services/step-5-feedback.services';
// Types
import { EvaluateNewOfferReturnType } from './types/step-5-evaluate-new-offer-return.resolvers.type';
import { EvaluateNewOfferPropsType } from './types/step-5-evaluate-new-offer-props.resolvers.type';
import { ApprovalReturnType } from './types/step-5-approval-return.resolvers.type';

const evaluateNewOfferResolver = async ({
  caseId,
  acceptance,
  newAmount,
}: EvaluateNewOfferPropsType): Promise<[EvaluateNewOfferReturnType | null, boolean]> => {
  const [response, { status }] = await evaluateNewOfferService({ caseId, acceptance, newAmount });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

const approvalResolver = async (caseId: string): Promise<[ApprovalReturnType | null, boolean]> => {
  const [response, { status }] = await approvalService(caseId);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

const rejectM2Resolver = async (caseId: string): Promise<[void | null, boolean]> => {
  const [response, { status }] = await rejectM2Service(caseId);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

export { evaluateNewOfferResolver, approvalResolver, rejectM2Resolver };
