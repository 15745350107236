// Enumerations
import { DepositMoneyFetchErrorEnumeration } from 'enumerations/deposit-money-fetch-error.enumeration';
const DOMAIN = 'cobranded.services.userSettings.privateArea.cbGeneric.cbError.';

export default {
  [DepositMoneyFetchErrorEnumeration.FETCH]: {
    ERROR_DESCRIPTION: `${DOMAIN}description`,
    ERROR_TITLE: `${DOMAIN}title`,
    BUTTON: `${DOMAIN}tryAgainButton`,
  },
};
