// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
import { PAYMENT_METHOD_VALUE } from '../constants/payment-method.constants';
// Type
import { Step1CreditLimitIncreaseUtilType } from './types/step-1-credit-limit-increase-util.type';

export const getPaymentMethodValueBy = ({
  accountBalancePercentage,
  fixedPaymentAmount,
  locale,
  mainCurrency,
  paymentMethodCode,
  formatMessage,
  formatNumber,
}: Step1CreditLimitIncreaseUtilType): string => {
  const id = PAYMENT_METHOD_VALUE[paymentMethodCode] || '';
  const amountFormatters = {
    [PAYMENT_METHOD_VALUE.FIXED_PAYMENT_AMOUNT]: `${formatMessage({
      id,
    })} ( ${formatNumber(fixedPaymentAmount, {
      currency: mainCurrency,
      ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
    })} )`,
    [PAYMENT_METHOD_VALUE.FULL_ACCOUNT_BALANCE]: formatMessage({ id }),
    [PAYMENT_METHOD_VALUE.PERCENTAGE_ACCOUNT_BALANCE]: `${formatMessage({
      id,
    })} ( ${formatNumber(accountBalancePercentage / 100, {
      style: MiscellaneousCurrencyConstants.AMOUNT_PERCENTAGE_FORMAT_PROPS.style,
    })} )`,
  };

  return amountFormatters[id] || '';
};
