// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import {
  FINANCING_DEPOSIT_MONEY_DEFAULT_ERROR_CONFIGURATION,
  FINANCING_DEPOSIT_MONEY_ERROR_CONFIGURATIONS,
} from './constants/financing-deposit-money.configuration.constants';
// Enumerations
import { DepositMoneyFetchErrorEnumeration } from 'enumerations/deposit-money-fetch-error.enumeration';
// Translations
import translations from './translations/financing-deposit-money.configuration.translations';
// Types
import { AppReturnConfigurationType } from 'configurations/types/app-return.configuration.type';
import { CardTableConfigurationType } from './types/financing-deposit-money.configuration.type';

const getDepositMoneyErrorConfiguration = ({
  buttonConfiguration,
  error,
  formatMessage,
}: CardTableConfigurationType): AppReturnConfigurationType | Record<string, any> => {
  const errorConfiguration = {
    ...FINANCING_DEPOSIT_MONEY_DEFAULT_ERROR_CONFIGURATION,
    ...FINANCING_DEPOSIT_MONEY_ERROR_CONFIGURATIONS[error || ''],
    formatMessage,
    translations: translations[error || ''],
    buttonConfiguration,
  };
  if (error === DepositMoneyFetchErrorEnumeration.FETCH) {
    return getFetchErrorConfiguration(errorConfiguration);
  }
  return {};
};

export { getDepositMoneyErrorConfiguration };
