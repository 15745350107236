// Enumeration
import { DepositMoneyFetchErrorEnumeration } from 'enumerations/deposit-money-fetch-error.enumeration';
// Resolver
import { fetchDepositMoneyResolver } from '../resolvers/financing-deposit-money.resolver';
// Types
import { DepositMoneyHandlersReturnType } from './types/financing-deposit-money-handlers-return.type';
import { LoadDepositMoneyHandlerPropsType } from './types/load-deposit-money-handler-props.type';

const loadDepositMoneyHandler = async ({
  cardId,
  setIssueBank,
  setMaxTransferLimit,
  setSections,
  setVirtualIban,
  setFetching,
  setError,
}: Omit<LoadDepositMoneyHandlerPropsType, 'wrapperError'>): Promise<void> => {
  setFetching(true);
  const [response, error] = await fetchDepositMoneyResolver(cardId);

  if (error) {
    setError(DepositMoneyFetchErrorEnumeration.FETCH);
  } else if (response) {
    const { issueBank, maxTransferLimit, sections, virtualIban } = response;
    setIssueBank(issueBank);
    setMaxTransferLimit(maxTransferLimit);
    setSections(sections);
    setVirtualIban(virtualIban);
  }
  setFetching(false);
};

const setOutProvidingErrorEvent = ({
  setError,
  wrapperError,
}: Pick<LoadDepositMoneyHandlerPropsType, 'setError' | 'wrapperError'>): any => {
  setError(wrapperError);
};

const FinancingDepositMoneyHandlers = ({
  setError,
  wrapperError,
  setIssueBank,
  setMaxTransferLimit,
  setSections,
  setVirtualIban,
  cardId,
  setFetching,
}: LoadDepositMoneyHandlerPropsType): DepositMoneyHandlersReturnType => ({
  handleSetOutProvidingErrorEvent: () => setOutProvidingErrorEvent({ setError, wrapperError }),
  handleLoadDepositMoney: () =>
    loadDepositMoneyHandler({
      setIssueBank,
      setMaxTransferLimit,
      setSections,
      setVirtualIban,
      cardId,
      setFetching,
      setError,
    }),
});

export default FinancingDepositMoneyHandlers;
