// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import FinancingFastCashSidebarComponent from './components/sidebar/financing-fast-cash-sidebar.component';
import FinancingOperativeLayoutComponent from '../../components/financing-operative-layout/financing-operative-layout.component';
import LimitModalComponent from './components/limit-modal/limit-modal.component';
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import { FINANCING_FAST_CASH_WIZARD_PROPS } from './constants/financing-fast-cash.constants';
// Contexts
import { FinancingFastCashContextConsumerHOC } from './contexts/financing-fast-cash.context';
// Hooks
import FinancingFastCashHook from './hooks/financing-fast-cash.hook';
// Translations
import { ERROR } from './translations/financing-fast-cash.translations';

const FinancingFastCashBaseView = (): React.ReactElement => {
  const { error, formatMessage, showLimitModal, handleCloseLimitModal, loading } =
    FinancingFastCashHook();

  return (
    <FetchErrorComponent
      fetching={loading}
      error={getFetchErrorConfiguration({
        error,
        formatMessage,
        translations: ERROR,
      })}
    >
      <FinancingOperativeLayoutComponent sidebar={<FinancingFastCashSidebarComponent />}>
        <WizardComponent {...FINANCING_FAST_CASH_WIZARD_PROPS} />
      </FinancingOperativeLayoutComponent>
      <LimitModalComponent isVisible={showLimitModal} onCloseModal={handleCloseLimitModal} />
    </FetchErrorComponent>
  );
};

const FinancingFastCashView = FinancingFastCashContextConsumerHOC(FinancingFastCashBaseView);

export { FinancingFastCashView };
