// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Handlers
import NextStepErrorCreditLimitIncreaseHandler from '../handlers/next-step-error-credit-limit-increase.component.handler';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { NextStepErrorCreditLimitIncreaseHookType } from './types/next-step-error-credit-limit-increase.component.hook.type';
// Tracking
import NextSetepErrorCreditLimitChangeTracking from '../trackings/next-step-error-credit-limit-increase.component.tracking';
import AppTrackingHook from 'tracking/app.tracking.hook';

const NextStepErrorCreditLimitIncreaseHook = (): NextStepErrorCreditLimitIncreaseHookType => {
  const { handleClickBackButtonTracking } = AppTrackingHook(
    NextSetepErrorCreditLimitChangeTracking
  );
  const { onCancelHybridFlow } = MobileConnectorContextConsumerHook();
  const { formatMessage } = useCbIntl();
  const navigate = useNavigate();
  const { handleOnClickBackButton } = NextStepErrorCreditLimitIncreaseHandler({
    onCancelHybridFlow,
    navigate,
    handleClickBackButtonTracking,
  });

  return {
    formatMessage,
    handleOnClickBackButton,
  };
};

export default NextStepErrorCreditLimitIncreaseHook;
