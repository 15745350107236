// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import FinancingEarlyRepaymentFirstStepInstalmentStatusComponent from './components/instalment-status/financing-early-repayment-first-step-instalment-status.component';
import FinancingEarlyRepaymentFirstStepInfoMessageComponent from './components/info-message/financing-early-repayment-first-step-info-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Hooks
import FinancingEarlyRepaymentFirstStepHook from './hooks/financing-early-repayment-first-step.hook';
// Styles
import {
  FinancingEarlyRepaymentFirstStepComponentStyled,
  FinancingEarlyRepaymentFirstStepDescriptionStyled,
  FinancingEarlyRepaymentFirstStepInstalmentStyled,
} from './financing-early-repayment-first-step.component.styled';
// Translations
import { EARLY_REPAYMENT_DESCRIPTION } from './translations/financing-early-repayment-first-step.translations';

const FinancingEarlyRepaymentFirstStepComponent = (): React.ReactElement | null => {
  const { cardNumber, eppDetails } = FinancingEarlyRepaymentFirstStepHook();

  if (!eppDetails) {
    return null;
  }

  const { amortizationTable, currentAmount, transactionInfo, originalAmount } = eppDetails,
    { installments } = amortizationTable,
    { description } = transactionInfo;

  return (
    <FinancingEarlyRepaymentFirstStepComponentStyled>
      <FinancingEarlyRepaymentFirstStepInstalmentStyled>
        <FinancingEarlyRepaymentFirstStepDescriptionStyled>
          <ParagraphComponent>
            <FormattedMessageComponent
              id={EARLY_REPAYMENT_DESCRIPTION}
              values={{
                cardNumber,
                description,
                originalAmount: <FormattedNumber {...EURO_FORMAT_OPTIONS} value={originalAmount} />,
              }}
            />
          </ParagraphComponent>
        </FinancingEarlyRepaymentFirstStepDescriptionStyled>
        <FinancingEarlyRepaymentFirstStepInstalmentStatusComponent
          {...{ currentAmount, installments }}
        />
      </FinancingEarlyRepaymentFirstStepInstalmentStyled>
      <FinancingEarlyRepaymentFirstStepInfoMessageComponent />
      <WizardStepFooterComponent />
    </FinancingEarlyRepaymentFirstStepComponentStyled>
  );
};

export default FinancingEarlyRepaymentFirstStepComponent;
