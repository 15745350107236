// Vendors
import { titleConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export default {
  STEP: {
    NEXT: {
      assetConfiguration: {
        id: 'chevron_right',
        sizes: {
          height: 16,
          width: 16,
        },
      },
    },
  },
  TEST_ID: 'credit-limit-increase-wizard',
  RIGHT_COLUMN_PROPS: {
    maxWidth: 276,
  },
  TITLE_PROPS: {
    bold: false,
    tag: titleConstants.TAGS.H2,
    testId: 'credit-limit-increase-title',
  },
  SIDEBAR: {
    PROPS: {
      testId: 'personal-email',
    },
    TITLE: {
      testId: 'credit-limit-increase-sidebar-text',
    },
  },
  EXIT_MODAL_DAYS: {
    FIVE: 5,
    FIFTEEN: 15,
  },
} as const;

export const StepEnumToIndexMap = {
  [CreditLimitIncreaseStepsEnumeration.SELECT_LIMIT_AMOUNT]: 1,
  [CreditLimitIncreaseStepsEnumeration.LABORAL_ECONOMIC_DATA]: 2,
  [CreditLimitIncreaseStepsEnumeration.SELECT_METHOD]: 3,
  [CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION]: 4,
  [CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION]: 4,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT]: 4,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED]: 4,
  [CreditLimitIncreaseStepsEnumeration.APPROVAL]: 5,
  [CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER]: 5,
  [CreditLimitIncreaseStepsEnumeration.PENDING]: 5,
  [CreditLimitIncreaseStepsEnumeration.RISK_REVIEW]: 5,
  [CreditLimitIncreaseStepsEnumeration.REJECT_M2]: 5,
  [CreditLimitIncreaseStepsEnumeration.END_NAVIGATION]: 5,
  [CreditLimitIncreaseStepsEnumeration.SIGNATURE]: 6,
  [CreditLimitIncreaseStepsEnumeration.SUCCESS]: 7, // Final feedback
  [CreditLimitIncreaseStepsEnumeration.COMPLETED]: 7, // Final feedback
  [CreditLimitIncreaseStepsEnumeration.ERROR_CMS_SUCCESS]: 7, // Final feedback
};

export const cancelUrl = `/${RoutesEnumeration.FINANCING}`;
