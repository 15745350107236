// Vendors
import React from 'react';
// Components
import FinancingDepositMoneySidebarComponent from './components/sidebar/financing-deposit-money-sidebar.component';
import FinancingDepositMoneyInformativeTextsComponent from './components/informative-component/financing-deposit-money-informative.component';
import FinancingDepositMoneyParameterTableComponent from './components/parameters-table/financing-deposit-money-parameters-table.component';
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getDepositMoneyErrorConfiguration } from './configurations/financing-deposit-money.configuration';
// Constants
import { FINANCING_DEPOSIT_MONEY_LOAD_BUTTON_PROPS } from './configurations/constants/financing-deposit-money.configuration.constants';
// Hooks
import FinancingDepositMoneyHook from './hooks/financing-deposit-money.hook';
// Styles
import {
  FinancingDepositMoneyContentStyled,
  FinancingDepositMoneyLeftContentStyled,
} from 'containers/financing/views/deposit-money/financing-deposit-money.view.styled';
import { LayoutGridContentRightComponentStyled } from 'styles/app.styled';
// Translations
import translations from './configurations/translations/financing-deposit-money.configuration.translations';
// Types
import { FinancingDepositMoneyViewType } from './types/financing-deposit-money.view.type';

const FinancingDepositMoneyView = ({
  error,
}: FinancingDepositMoneyViewType): React.ReactElement<FinancingDepositMoneyViewType> => {
  const {
    error: componentError,
    fetching,
    issueBank,
    maxTransferLimit,
    sections,
    virtualIban,
    limit,
    formatMessage,
    handleReloadAndClearPageErrorButtonClick,
  } = FinancingDepositMoneyHook({
    error,
  });

  return (
    <FinancingDepositMoneyContentStyled>
      <FetchErrorComponent
        {...{ fetching, error: componentError }}
        error={getDepositMoneyErrorConfiguration({
          error: componentError,
          formatMessage,
          buttonConfiguration: {
            ...FINANCING_DEPOSIT_MONEY_LOAD_BUTTON_PROPS,
            children: <FormattedMessageComponent id={translations.FETCH.BUTTON} />,
            onClick: handleReloadAndClearPageErrorButtonClick,
          },
        })}
      >
        <FinancingDepositMoneyLeftContentStyled>
          <FinancingDepositMoneyInformativeTextsComponent
            {...{ issueBank, limit, maxTransferLimit, virtualIban }}
          />
          <FinancingDepositMoneyParameterTableComponent {...{ sections }} />
        </FinancingDepositMoneyLeftContentStyled>
        <LayoutGridContentRightComponentStyled>
          <FinancingDepositMoneySidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </FetchErrorComponent>
    </FinancingDepositMoneyContentStyled>
  );
};

export { FinancingDepositMoneyView };
