// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingFastCashContextConsumerHook } from 'containers/financing/views/fast-cash/contexts/financing-fast-cash.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Handlers
import FinancingFastCashSecondStepHandlers from './../handlers/financing-fast-cash-second-step.handlers';
// Types
import { FinancingFastCashSecondStepHookType } from './types/financing-fast-cash-second-step.hook.type';

const FinancingFastCashSecondStepHook = (): FinancingFastCashSecondStepHookType => {
  const { amountValue, challenge, setChallenge, setError } = FinancingFastCashContextConsumerHook();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {},
    { primaryAccountId = CharactersEnum.NO_VALUE } = currentCard?.contractInfo || {};

  const [challengeError, setChallengeError] = useState(false);

  return {
    params: { accountId: primaryAccountId, amount: amountValue ?? 0 },
    cardId,
    challengeError,
    ...FinancingFastCashSecondStepHandlers({
      challenge,
      setChallenge,
      setChallengeError,
      setError,
    }),
  };
};

export default FinancingFastCashSecondStepHook;
