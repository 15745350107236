// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import FinancingFastCashFirstStepActionsHook from './hooks/financing-fast-cash-first-step-actions.hook';

const FinancingFastCashFirstStepActionsComponent = (): React.ReactElement => {
  const { disableNextBtn } = FinancingFastCashFirstStepActionsHook();

  return <WizardStepFooterComponent rightButtonConfiguration={{ disabled: disableNextBtn }} />;
};

export default FinancingFastCashFirstStepActionsComponent;
