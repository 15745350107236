// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import EmploymentDataCreditLimitIncreaseComponent from './sections/employment-data/employment-data-credit-limit-increase.component';
import ExpensesCreditLimitIncreaseComponent from './sections/expenses/expenses-credit-limit-increase.component';
import PersonalSituationCreditLimitIncreaseComponent from './sections/personal-situation/personal-situation-credit-limit-increase.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants from './constants/credit-limit-increase.component.constants';
import { cancelUrl } from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
// Hooks
import ExitModalHook from 'containers/financing/views/credit-limit-increase/hooks/exit-modal.hook';
import Step2CreditLimitIncreaseHook from './hooks/step-2-credit-limit-increase.component.hook';
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { Step2CreditLimitIncreaseStyled } from './step-2-credit-limit-increase.component.styled';
// Translations
import translations from './translations/step-2-credit-limit-increase.translations';
import { translationsStep1Or2 } from '../../translations/exit-modal-credit-limit-increase.component.translations';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';
import BoldMessageComponent from 'components/bold-message/bold-message.component';

const Step2CreditLimitIncreaseComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { showModal, setShowModal, cancelModalLinkClick } = ExitModalHook();
  const {
    isDisabled,
    handleSetLaboralEconomicData,
    handleSetLaboralEconomicDataBack,
    handleOpenExternalUrlOnHybridFlow,
  } = Step2CreditLimitIncreaseHook();

  return (
    <Step2CreditLimitIncreaseStyled>
      <ParagraphComponent>{formatMessage({ id: translations.DESCRIPTION })}</ParagraphComponent>
      <PersonalSituationCreditLimitIncreaseComponent />
      <EmploymentDataCreditLimitIncreaseComponent />
      <ExpensesCreditLimitIncreaseComponent />
      <ParagraphComponent>
        {formatMessage({ id: translations.SCHUFA_TEXT }, {
          schufaUrl: chunk =>
            makeChunkLinkIntlUtil({
              chunk,
              onClick: () => handleOpenExternalUrlOnHybridFlow(constants.SHUFA_URL),
            }),
          bold: chunks => <BoldMessageComponent>{chunks}</BoldMessageComponent>,
        } as any)}
      </ParagraphComponent>
      <WizardStepFooterComponent
        hasDefaultLeftButton={constants.STEP_2_WIZARD_FOOTER_PROPS.hasDefaultLeftButton}
        rightButtonConfiguration={{
          onClick: handleSetLaboralEconomicData,
          children: formatMessage({ id: translations.SAVE_AND_CONTINUE }),
          disabled: isDisabled,
        }}
        leftButtonConfiguration={{
          onClick: handleSetLaboralEconomicDataBack,
        }}
        cancelModal={{
          cancelUrl,
          showModal,
          setShowModal,
          clicks: {
            linkClick: cancelModalLinkClick,
            buttonClick: () => setShowModal(false),
          },
          translations: {
            TITLE: translationsStep1Or2.TITLE,
            DESCRIPTION_01: translationsStep1Or2.DESCRIPTION,
            LINK: translationsStep1Or2.LINK_TEXT,
            BUTTON: translationsStep1Or2.PRIMARY_BUTTON_TEXT,
          },
        }}
      />
    </Step2CreditLimitIncreaseStyled>
  );
};

export default Step2CreditLimitIncreaseComponent;
