// Vendors
import { useEffect, useState } from 'react';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Contexts
import { FinancingFastCashContextConsumerHook } from 'containers/financing/views/fast-cash/contexts/financing-fast-cash.context';
// Handlers
import FinancingFastCashAmountHandlers from '../handlers/amount.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import AmountTrackingHandlers from '../handlers/amount.handlers.tracking';
// Translations
import { INPUT_ERRORS } from '../translations/amount.translations';
// Types
import { FinancingFastCashAmountHookType } from './types/amount.hook.type';

const FinancingFastCashAmountHook = (): FinancingFastCashAmountHookType => {
  const { formatMessage, formatNumber } = useCbIntl();
  const { fastCashLimits, fastCashCommission, amountValue, setAmountValue, setDisableNextBtn } =
    FinancingFastCashContextConsumerHook();
  const [inputErrorMsg, setInputErrorMsg] = useState<string | null>('');

  const { minAmount = 0, maxAmount = 0 } = fastCashLimits || {};

  const AmountHandlers = FinancingFastCashAmountHandlers({
    minAmount,
    maxAmount,
    setAmountValue,
    setInputErrorMsg,
  });

  useEffect(() => {
    setDisableNextBtn(!amountValue || !!inputErrorMsg);
  }, [amountValue, inputErrorMsg, setDisableNextBtn]);

  return {
    amountValue,
    formatMessage,
    inputErrorMsg: inputErrorMsg ? formatMessage({ id: INPUT_ERRORS[inputErrorMsg] }) : '',
    fastCashCommission,
    maxAmount: formatNumber(maxAmount, {
      currency: fastCashLimits?.currencyCode,
      ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
    }),
    minAmount: formatNumber(minAmount, {
      currency: fastCashLimits?.currencyCode,
      ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
    }),
    ...AmountHandlers,
    ...AppTrackingHook(AmountTrackingHandlers),
  };
};

export default FinancingFastCashAmountHook;
