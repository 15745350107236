// Vendors
import React, { ReactNode } from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import { FINANCING_ROUTER_ROUTES } from './constants/financing-router.constants';

const FinancingRouterComponent = (): React.ReactElement => (
  <Routes>
    {FINANCING_ROUTER_ROUTES.map(
      ({ element: RouteComponent, elementProps, props }, index: number): ReactNode => (
        <Route {...props} element={<RouteComponent {...elementProps} />} key={index} />
      )
    )}
  </Routes>
);

export { FinancingRouterComponent };
