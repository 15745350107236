// Vendors
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// Contexts
import {
  CALLBACK_WEBID_SEARCH_PARAMETER,
  CALLBACK_WEBID_SUCCESS_VALUE,
} from './constants/credit-limit-increase.view.hook.constants';
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from '../contexts/credit-limit-increase.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Handlers
import { InitHandlers } from '../handlers/step-0-init.handler';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import FinalFeedbackTracking from '../trackings/final-feedback.component.tracking';
// Types
import { CreditLimitIncreaseContainerHookType } from './types/credit-limit-increase.container.hook.type';

const CreditLimitIncreaseContainerHook = (): CreditLimitIncreaseContainerHookType => {
  const { formatMessage } = useCbIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobileOrTablet } = DeviceTypeHook();
  const creditLimitIncreaseContext = CreditLimitIncreaseContextConsumerHook();
  const { step2, step4, finalFeedback, wizardStatus } = creditLimitIncreaseContext;
  const { handleEnterViewTracking, handleGoToFinancingTracking } =
    AppTrackingHook(FinalFeedbackTracking);
  const { profile, currentCard } = AppContextConsumerHook(),
    { amlNextReviewDate } = profile?.kycReviewDates || {},
    { id: cardId } = currentCard || {};
  const { handleGetInitCase, handleGetDefaultFinalFeedback } = InitHandlers({
    creditLimitContext: creditLimitIncreaseContext,
    cardId,
  });
  const defaultResult = handleGetDefaultFinalFeedback();

  useEffect(() => {
    const isWebIdSuccessFul =
      searchParams.get(CALLBACK_WEBID_SEARCH_PARAMETER) === CALLBACK_WEBID_SUCCESS_VALUE;

    handleGetInitCase({ amlNextReviewDate, callbackWebId: isWebIdSuccessFul, setSearchParams });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    defaultResult !== undefined && handleEnterViewTracking(wizardStatus.currentStepInText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultResult]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [
    wizardStatus.currentStep,
    wizardStatus.error,
    wizardStatus.fetching,
    step2.isRejectM1,
    step2.isLoadingNextRequest,
    step4.isLoadingNextRequest,
  ]);

  return {
    creditLimitIncreaseContext,
    isSuccessTax:
      wizardStatus.currentStepInText === CreditLimitIncreaseStepsEnumeration.SUCCESS &&
      finalFeedback.taxIdRequired,
    defaultResult,
    isMobileOrTablet,
    formatMessage,
    handleGoToFinancingTracking,
  };
};

export default CreditLimitIncreaseContainerHook;
