// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { NavigateToFinancingType } from './types/navigate-to-financing.utils.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

export const navigateToFinancing = ({
  onCancelHybridFlow,
  tracking,
}: NavigateToFinancingType): void => {
  tracking?.();
  onCancelHybridFlow(generateAbsoluteRoute(RoutesEnumeration.FINANCING));
};
