// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  CANCEL_EPP_SERVICE_CONFIGURATION,
  FETCH_EPP_DETAILS_SERVICE_CONFIGURATION,
} from './constants/financing-early-repayment.services.constants';
// Types
import { EppCardType } from 'types/epp-card.type';
import { GetEppDetailsServiceType } from './types/get-epp-details-service.type';
import { CancelEppServiceType } from './types/cancel-epp-service.type';
import { ChallengeType } from 'types/challenge.type';

const getEppDetailsService = async ({
  cardId,
  eppId,
}: GetEppDetailsServiceType): Promise<[EppCardType | null, Response]> =>
  apiUtilsCb.get({ ...FETCH_EPP_DETAILS_SERVICE_CONFIGURATION, pathParams: { cardId, eppId } });

const cancelEppService = async ({
  cardId,
  eppId,
  progressId,
}: CancelEppServiceType): Promise<[ChallengeType | null, Response]> =>
  apiUtilsCb.post({
    ...CANCEL_EPP_SERVICE_CONFIGURATION,
    customHeaders: progressId ? { progressId } : {},
    pathParams: { cardId, eppId },
  });

export { cancelEppService, getEppDetailsService };
