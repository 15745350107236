// Enumerations
import { BankStatusEnumeration } from '../enumerations/bank-status.enumeration';

const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step4.accountAggregation.';

export const step4AccountAggregationTranslations = {
  TITLE: `${DOMAIN}title`,
  SUBTITLE: `${DOMAIN}subtitle`,
  SMALL_SUBTITLE: `${DOMAIN}description`,
  ADD_BANK: `${DOMAIN}addBank`,
  ADDED_BANKS: `${DOMAIN}addedBanks`,
  STATUS: `${DOMAIN}status`,
  [BankStatusEnumeration.LOADING]: `${DOMAIN}loading`,
  [BankStatusEnumeration.OK]: `${DOMAIN}ok`,
  [BankStatusEnumeration.KO]: `${DOMAIN}ko`,
  INVALID_BANK: `${DOMAIN}invalidBank`,
  INVALID_BANK_DESCRIPTION: `${DOMAIN}invalidBankDescription`,
  INVALID_BANK_DESCRIPTION_2: `${DOMAIN}invalidBankDescription2`,
  GO_TO_UPLOAD_DOCUMENTATION: `${DOMAIN}goToUploadDocumentation`,
  ADDING_NEW_BANK: `${DOMAIN}addingNewBank`,
};
