// Constants
import { ONE_MINUTE } from './constants/step-4-account-aggregation.utils.constants';
// Enumerations
import { BankStatusEnumeration } from '../../components/step4/account-aggregation/enumerations/bank-status.enumeration';
// Resolvers
import { getInitCaseResolver } from '../../resolvers/step-0-init.resolvers';
// Types
import { TinkDataType } from 'containers/financing/views/credit-limit-increase/resolvers/types/tink-data.type';
import { TinkDataFromTinkType } from '../types/tink-data-params.type';
import { RecursiveInitToGetBankDataPropsType } from './types/recursive-ini-to-get-bank-data-props.type';
import { BankDataType } from '../../services/types/bank-data.type';
import { SetKOInNewBankAggregationPropsType } from './types/set-ko-in-new-bank-aggregation-props.type';
import { Step4Type } from '../../contexts/types/step-4-credit-limit-increase.context.type';

const getBankLength = (stepData: Step4Type['stepData']): number => stepData?.bankData?.length ?? 0;

export const mapStep4Data = (tinkData: TinkDataFromTinkType): TinkDataType => ({
  userId: tinkData.userid,
  accountVerificationReportId: tinkData.accountVerificationReportId,
  transactionReportId: tinkData.transactionReportId,
  expensesReportId: tinkData.expenseCheckReportId,
  incomesReportId: tinkData.incomeCheckReportId,
  riskReportId: tinkData.riskInsightsReportId,
});

export const mapParamsToCamelCase = (str: string): string => {
  return str
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
};

export const setKOInNewBankAggregation = ({
  step4,
  setStep4,
}: SetKOInNewBankAggregationPropsType): void => {
  const updatedBankAccounts = step4.stepData?.bankData?.map((bankData: BankDataType) => {
    if (bankData.status === BankStatusEnumeration.LOADING) {
      return { ...bankData, status: BankStatusEnumeration.KO };
    }

    return bankData;
  });

  setStep4({
    ...step4,
    tinkData: undefined,
    stepData: { ...step4.stepData, bankData: updatedBankAccounts },
  });
};

export const recursiveInitToGetBankData = async (
  props: RecursiveInitToGetBankDataPropsType
): Promise<void> => {
  const { cardId, dateOfFirstTime, step4, setStep4 } = props;
  const dateNow = new Date();
  const diff = dateNow.getTime() - dateOfFirstTime.getTime();

  if (diff > ONE_MINUTE) {
    return setKOInNewBankAggregation({ step4, setStep4 });
  }

  const [initResponse, error] = await getInitCaseResolver({ cardId, callbackWebId: false });

  if (error) {
    return setKOInNewBankAggregation({ step4, setStep4 });
  }

  if (initResponse) {
    const oldLengthBankData = getBankLength(step4.stepData);
    const newLengthBankData = getBankLength(initResponse.stepData);

    if (newLengthBankData === oldLengthBankData - 1) {
      setTimeout(() => recursiveInitToGetBankData(props), 5000);
    } else {
      setStep4({ ...step4, tinkData: undefined, stepData: initResponse.stepData });
    }
  }
};

export const hasAccountVerificationAndTransaction = (
  accountVerificationReportId?: string,
  transactionReportId?: string
): boolean => Boolean(accountVerificationReportId && transactionReportId);
