// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { getLocaleFromBrowser } from '@openbank/cf-ui-framework';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CountriesLocaleEnum } from '@openbank/cf-ui-static-data';
// Handlers
import ChangeCreditLimitHandler from '../handlers/change-credit-limit.handler';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Trackings
import Step1CreditLimitChageTracking from '../../step1/trackings/step-1-credit-limit-increase.tracking';
// Types
import { ChangeCreditLimitHookReturn } from './types/change-credit-limit.hook.type';

// eslint-disable-next-line complexity
const ChangeCreditLimitHook = (): ChangeCreditLimitHookReturn => {
  const { handleClickChangeCreditLimitBoxTracking } = AppTrackingHook(
    Step1CreditLimitChageTracking
  );
  const { formatMessage, formatNumber } = useCbIntl();
  const [inputError, setInputError] = useState<string>('');
  const { currentCard } = AppContextConsumerHook();
  const { currencyCode } = currentCard || {};
  const {
    paymentMethodCode = '',
    fixedPaymentAmount = '',
    accountBalancePercentage = '',
    limit,
  } = currentCard?.creditDetails || {};
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step1, setStep1, step5, setStep5, wizardStatus, setWizardStatus } = creditLimitContext;
  const isStep1 = wizardStatus.currentStep === 1;
  const { currentCreditLimit, minAmount, maxAmount } = isStep1 ? step1 : step5;
  const currentLimit = (limit ?? minAmount) as number;
  const locale = getLocaleFromBrowser() || CountriesLocaleEnum.GERMANY;
  const { handleOnChange } = ChangeCreditLimitHandler({
    currentLimit,
    step: isStep1 ? step1 : step5,
    wizardStatus,
    setInputError,
    setStep: isStep1 ? setStep1 : setStep5,
    setWizardStatus,
  });

  return {
    locale,
    defaultValue: currentCreditLimit ?? currentLimit,
    fixedPaymentAmount,
    accountBalancePercentage,
    inputError,
    limit,
    mainCurrency: currencyCode,
    maxAmount,
    minAmount,
    paymentMethodCode,
    formatMessage,
    formatNumber,
    handleOnChange,
    handleClickChangeCreditLimitBoxTracking: isStep1
      ? handleClickChangeCreditLimitBoxTracking
      : () => undefined,
  };
};

export default ChangeCreditLimitHook;
