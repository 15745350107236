// Vendors
import React from 'react';
// Components
import {
  AssetComponent,
  InputPrefixSuffixComponent,
  ParagraphComponent,
  TooltipComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import TotalAmountComponent from './components/total-amount/total-amount.component';
// Constants
import constants from './constants/amount.constants';
// Hooks
import FinancingFastCashAmountHook from './hooks/amount.hook';
// Styles
import {
  AmountContainerStyled,
  AmountInputPrefixSuffixComponentStyled,
  AmountLeftTitleWrapperStyled,
  AmountLeftWrapperStyled,
  AmountRightWrapperStyled,
} from './amount.component.styled';
// Translations
import { AMOUNT_TITLE, TOOLTIP } from './translations/amount.translations';
// Utils
import { getInputConfiguration } from './configurations/amount.component.configurations';

const AmountComponent = (): React.ReactElement => {
  const {
    formatMessage,
    maxAmount,
    minAmount,
    inputErrorMsg,
    fastCashCommission,
    handleOnChangeValue,
    amountValue,
    handleInputClickTracking,
    handleInputErrorTracking,
  } = FinancingFastCashAmountHook();

  const { currency } = fastCashCommission || {};

  return (
    <AmountContainerStyled {...constants.PROPS}>
      <AmountLeftWrapperStyled>
        <AmountLeftTitleWrapperStyled>
          <ParagraphComponent {...constants.TITLE_PARAGRAPH_PROPS}>
            <FormattedMessageComponent id={AMOUNT_TITLE} />
          </ParagraphComponent>
          <TooltipComponent content={formatMessage({ id: TOOLTIP })}>
            <AssetComponent {...constants.ASSET_PROPS} />
          </TooltipComponent>
        </AmountLeftTitleWrapperStyled>
        <AmountInputPrefixSuffixComponentStyled
          errorCalc={constants.AMOUNT_MULTIPLE_CALC}
          errorLength={inputErrorMsg?.length}
          errorMaxLength={constants.AMOUNT_ERROR_LENGTH}
        >
          <InputPrefixSuffixComponent
            {...getInputConfiguration({
              amountValue,
              currency,
              formatMessage,
              handleInputClickTracking,
              handleInputErrorTracking,
              handleOnChangeValue,
              inputErrorMsg,
              maxAmount,
              minAmount,
            })}
          />
        </AmountInputPrefixSuffixComponentStyled>
      </AmountLeftWrapperStyled>
      <AmountRightWrapperStyled>
        <TotalAmountComponent />
      </AmountRightWrapperStyled>
    </AmountContainerStyled>
  );
};

export default AmountComponent;
