// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { DepositMoneyErrorTrackingHandlersType } from './types/financing-deposit-money-error.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const reloadAndClearPageErrorButtonClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'tryAgain',
  },
  type: TrackingEventsEnum.LINK,
});

const DepositMoneyErrorTrackingHandlers = (
  track: TrackBuilderType
): DepositMoneyErrorTrackingHandlersType => ({
  handleReloadAndClearPageErrorButtonClickTracking: () =>
    track(reloadAndClearPageErrorButtonClickTracking()),
});

export default DepositMoneyErrorTrackingHandlers;
