// Vendors
import React from 'react';
// Components
import { CreditChangeInfoCard } from '../credit-change-info-card/credit-change-info-card.component';
import {
  AssetComponent,
  LoaderComponent,
  ParagraphComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants, {
  cancelUrl,
} from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
import { constantsStep6 } from './constants/step-6-credit-limit-increase.constants';
import ExitModalHook from 'containers/financing/views/credit-limit-increase/hooks/exit-modal.hook';
import { Step6CreditLimitIncreaseHook } from './hooks/step-6-credit-limit-increase.component.hook';
// Styles
import {
  LoeaderComponentContainerStyled,
  Step6CreditLimitIncreaseContentFirstSetionStyled,
  Step6CreditLimitIncreaseContentInfoSetionStyled,
  Step6CreditLimitIncreaseContentSecondSetionStyled,
  Step6CreditLimitIncreaseTextSectionStyled,
  Step6CreditLimitIncreaseWebIdDisclaimerStyled,
} from './step-6-credit-limit-increase.component.styled';
// Translations
import { translationsOtherSteps } from '../../translations/exit-modal-credit-limit-increase.component.translations';
import { step6Translations } from './translations/step-6-credit-limit-increase.translations';

const Step6CreditLimitIncreaseComponent = (): React.ReactElement => {
  const {
    disabledButtonsCancelModal,
    showModal,
    setShowModal,
    cancelModalButtonClick,
    cancelModalLinkClick,
  } = ExitModalHook();
  const {
    currencyCode,
    currentCreditLimit,
    isWebsocketActive,
    newCreditLimit,
    formatMessage,
    handleOnClickSignWithWebID,
  } = Step6CreditLimitIncreaseHook();

  return !isWebsocketActive ? (
    <>
      <Step6CreditLimitIncreaseTextSectionStyled>
        <ParagraphComponent>{formatMessage({ id: step6Translations.SUBTITLE })}</ParagraphComponent>
      </Step6CreditLimitIncreaseTextSectionStyled>
      <Step6CreditLimitIncreaseContentFirstSetionStyled>
        <TitleComponent>
          {formatMessage({ id: step6Translations.FIRST_SECTION_TITLE })}
        </TitleComponent>
        <ParagraphComponent>
          {formatMessage({ id: step6Translations.FIRST_SECTION_TEXT })}
        </ParagraphComponent>
      </Step6CreditLimitIncreaseContentFirstSetionStyled>
      <Step6CreditLimitIncreaseContentSecondSetionStyled>
        <TitleComponent>
          {formatMessage({ id: step6Translations.SECOND_SECTION_TITLE })}
        </TitleComponent>
        <ParagraphComponent>
          {formatMessage({ id: step6Translations.SECOND_SECTION_TEXT })}
        </ParagraphComponent>
      </Step6CreditLimitIncreaseContentSecondSetionStyled>
      <Step6CreditLimitIncreaseContentInfoSetionStyled>
        <CreditChangeInfoCard {...{ currentCreditLimit, newCreditLimit, currency: currencyCode }} />
      </Step6CreditLimitIncreaseContentInfoSetionStyled>
      <Step6CreditLimitIncreaseWebIdDisclaimerStyled>
        <AssetComponent {...constantsStep6.ASSET_PROPS}></AssetComponent>
        <ParagraphComponent>
          {formatMessage({ id: step6Translations.WEB_ID_DISCLAIMER })}
        </ParagraphComponent>
      </Step6CreditLimitIncreaseWebIdDisclaimerStyled>
      <WizardStepFooterComponent
        rightButtonConfiguration={{
          onClick: handleOnClickSignWithWebID,
          children: formatMessage({ id: step6Translations.NEXT }),
        }}
        cancelModal={{
          cancelUrl,
          disabledButtons: disabledButtonsCancelModal,
          showModal,
          setShowModal,
          clicks: {
            buttonClick: cancelModalButtonClick,
            linkClick: cancelModalLinkClick,
          },
          translations: {
            TITLE: translationsOtherSteps.TITLE,
            DESCRIPTION_01: translationsOtherSteps.DESCRIPTION,
            VALUESDESCRIPTION_01: { days: constants.EXIT_MODAL_DAYS.FIVE },
            LINK: translationsOtherSteps.LINK_TEXT,
            BUTTON: translationsOtherSteps.PRIMARY_BUTTON_TEXT,
          },
        }}
      />
    </>
  ) : (
    <LoeaderComponentContainerStyled>
      <LoaderComponent {...constantsStep6.LOADER_PROPS} />
    </LoeaderComponentContainerStyled>
  );
};

export default Step6CreditLimitIncreaseComponent;
