// Contexts
import { FinancingFastCashContextConsumerHook } from 'containers/financing/views/fast-cash/contexts/financing-fast-cash.context';
// Types
import { FinancingFastCashFirstStepActionsHookReturnType } from './types/financing-fast-cash-first-step-actions.hook.type';

const FinancingFastCashFirstStepActionsHook =
  (): FinancingFastCashFirstStepActionsHookReturnType => {
    const { disableNextBtn } = FinancingFastCashContextConsumerHook();

    return {
      disableNextBtn,
    };
  };

export default FinancingFastCashFirstStepActionsHook;
