// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import {
  cancelEppService,
  getEppDetailsService,
} from '../services/financing-early-repayment.services';
// Types
import { EppCardType } from 'types/epp-card.type';
import { GetEppDetailsResolverType } from './types/get-epp-details-resolver.type';
import { CancelEppResolverType } from './types/cancel-epp-resolver.type';
import { ChallengeType } from 'types/challenge.type';

const getEppDetailsResolver = async (
  params: GetEppDetailsResolverType
): Promise<[EppCardType] | [null, boolean]> => {
  const [response, { status }] = await getEppDetailsService(params);

  return requestHasSuccessStatus(status) ? [response as EppCardType] : [null, true];
};

const cancelEppResolver = async (
  params: CancelEppResolverType
): Promise<[null] | [ChallengeType | null, number]> => {
  const [response, { status }] = await cancelEppService(params);

  return requestHasSuccessStatus(status) ? [null] : [response, status];
};

export { cancelEppResolver, getEppDetailsResolver };
