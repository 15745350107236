// Types
import {
  DepositMoneyErrorBuilderReturnHandlersType,
  DepositMoneyErrorHandlersType,
} from './types/financing-deposit-money-error.handlers.type';

const reloadAndClearPageErrorButtonClick = ({
  handleReloadAndClearPageErrorButtonClickTracking,
}: DepositMoneyErrorHandlersType) => {
  handleReloadAndClearPageErrorButtonClickTracking();
  window.location.reload();
};

const DepositMoneyErrorHandlers = (
  props: DepositMoneyErrorHandlersType
): DepositMoneyErrorBuilderReturnHandlersType => ({
  handleReloadAndClearPageErrorButtonClick: () => reloadAndClearPageErrorButtonClick(props),
});

export default DepositMoneyErrorHandlers;
