// Enumerations
import { DepositMoneyFetchErrorEnumeration } from 'enumerations/deposit-money-fetch-error.enumeration';

const FINANCING_DEPOSIT_MONEY_LOAD_BUTTON_PROPS = {
  testId: 'card-settings-deposit-money-error-button',
};

const FINANCING_DEPOSIT_MONEY_DEFAULT_ERROR_CONFIGURATION = {
  error: true,
};

const FINANCING_DEPOSIT_MONEY_ERROR_CONFIGURATIONS = {
  [DepositMoneyFetchErrorEnumeration.FETCH]: {
    assetId: 'oops-alternative',
  },
};

export {
  FINANCING_DEPOSIT_MONEY_LOAD_BUTTON_PROPS,
  FINANCING_DEPOSIT_MONEY_DEFAULT_ERROR_CONFIGURATION,
  FINANCING_DEPOSIT_MONEY_ERROR_CONFIGURATIONS,
};
