// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Translations
import { step5Translations } from '../translations/step-5-credit-limit-increase.translations';
// Types
import { CreditLimitIncreaseStep5StatusType } from '../hooks/types/step-5-status.type';
import { PPI_STATUS_WITH_WARNING_MESSAGE } from './enumerations/step-5-credit-limit-increase.component.utils.enumeration';
import { ShowPPIWarningMessagePropType } from './types/step-5-credit-limit-increase.component.utils.type';

// eslint-disable-next-line complexity
const getAssetId = (status: CreditLimitIncreaseStep5StatusType): string => {
  switch (status) {
    case CreditLimitIncreaseStepsEnumeration.APPROVAL:
      return 'Success_Alternative';
    case CreditLimitIncreaseStepsEnumeration.END_NAVIGATION:
    case CreditLimitIncreaseStepsEnumeration.REJECT_M2:
      return 'oops-alternative';
    case CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER:
      return 'working-on-it-unhappy-alternative';
    case CreditLimitIncreaseStepsEnumeration.PENDING:
    case CreditLimitIncreaseStepsEnumeration.RISK_REVIEW:
      return 'wait-alternative';
    default:
      return '';
  }
};

// eslint-disable-next-line complexity
const getFeedbackTitle = (status: CreditLimitIncreaseStep5StatusType): string => {
  switch (status) {
    case CreditLimitIncreaseStepsEnumeration.APPROVAL:
      return step5Translations.APPROVAL_FEEDBACK_TITLE;
    case CreditLimitIncreaseStepsEnumeration.END_NAVIGATION:
    case CreditLimitIncreaseStepsEnumeration.REJECT_M2:
      return step5Translations.REJECTION_FEEDBACK_TITLE;
    case CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER:
      return step5Translations.NEW_OFFER_FEEDBACK_TITLE;
    case CreditLimitIncreaseStepsEnumeration.PENDING:
      return step5Translations.PENDING_FEEDBACK_TITLE;
    case CreditLimitIncreaseStepsEnumeration.RISK_REVIEW:
      return step5Translations.DOCUMENTATION_UPLOADED;
    default:
      return '-';
  }
};

// eslint-disable-next-line complexity
const getFeedbackDescription = (status: CreditLimitIncreaseStep5StatusType): string => {
  switch (status) {
    case CreditLimitIncreaseStepsEnumeration.APPROVAL:
      return step5Translations.APPROVAL_FEEDBACK_DESCRIPTION;
    case CreditLimitIncreaseStepsEnumeration.END_NAVIGATION:
    case CreditLimitIncreaseStepsEnumeration.REJECT_M2:
      return step5Translations.REJECTION_FEEDBACK_DESCRIPTION;
    case CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER:
      return step5Translations.NEW_OFFER_FEEDBACK_DESCRIPTION;
    case CreditLimitIncreaseStepsEnumeration.PENDING:
      return step5Translations.PENDING_FEEDBACK_DESCRIPTION;
    case CreditLimitIncreaseStepsEnumeration.RISK_REVIEW:
      return step5Translations.DOCUMENTATION_UPLOADED_DESCRIPTION;
    default:
      return '-';
  }
};

const showPPIWarningMessage = ({
  status,
  creditLimitIncreaseStatus,
}: ShowPPIWarningMessagePropType): boolean =>
  Boolean(
    status &&
      [
        CreditLimitIncreaseStepsEnumeration.APPROVAL,
        CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER,
      ].includes(creditLimitIncreaseStatus) &&
      Object.values(PPI_STATUS_WITH_WARNING_MESSAGE).includes(
        status as PPI_STATUS_WITH_WARNING_MESSAGE
      )
  );

export { getAssetId, getFeedbackTitle, getFeedbackDescription, showPPIWarningMessage };
