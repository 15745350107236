// Vendors
import useCbIntl from 'hooks/useCbIntl';
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingFastCashContextConsumerHook } from '../contexts/financing-fast-cash.context';
// Handlers
import FinancingFastCashHandlers from '../handlers/fast-cash/financing-fast-cash.handlers';
// Types
import { FinancingFastCashReturnHookType } from './types/financing-fast-cash.hook.type';

const FinancingFastCashHook = (): FinancingFastCashReturnHookType => {
  const { formatMessage } = useCbIntl();

  const [showLimitModal, setShowLimitModal] = useState<boolean>(false);

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {},
    { availableAmount } = currentCard?.creditDetails || {};

  const { error, loading, setError, setFastCashCommission, setFastCashLimits, setLoading } =
    FinancingFastCashContextConsumerHook();

  const { handleLoadFastCashLimits, handleCloseLimitModal } = FinancingFastCashHandlers({
    cardId,
    availableAmount,
    setLoading,
    setError,
    setFastCashLimits,
    setFastCashCommission,
    setShowLimitModal,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cardId) {
      handleLoadFastCashLimits();
    }
  }, [cardId]);

  return {
    error,
    formatMessage,
    handleCloseLimitModal,
    loading,
    showLimitModal,
  };
};

export default FinancingFastCashHook;
