// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { ExpiredKycModalTrackingHandlersType } from './types/expired-kyc-modal.handlers.type';
import { ExpiredKycModalTrackingHandlersReturnType } from './types/expired-kyc-modal-return.handlers.type';
// Utils
import { navigateToFinancing } from 'containers/financing/views/credit-limit-increase/utils/navigate-to-financing.utils';
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

export const ExpiredKycModalHandlers = ({
  handleClickButtonModalTracking,
  handleClickCloseModalTracking,
  handleClickLinkModalTracking,
  onCancelHybridFlow,
  navigate,
}: ExpiredKycModalTrackingHandlersType): ExpiredKycModalTrackingHandlersReturnType => ({
  handleNavigateToPersonalData: () => {
    handleClickButtonModalTracking();
    onCancelHybridFlow(generateAbsoluteRoute(RoutesEnumeration.PERSONAL_INFORMATION));
  },
  handleNavigateToFincancing: () =>
    navigateToFinancing({ onCancelHybridFlow, navigate, tracking: handleClickLinkModalTracking }),
  handleCloseModal: () =>
    navigateToFinancing({ onCancelHybridFlow, navigate, tracking: handleClickCloseModalTracking }),
});
