// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/credit-change-info-card.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styled
import {
  CreditChangeInfoCardSectionStyled,
  CreditChangeInfoCardInfoStyled,
} from './credit-change-info-card.styled';
// Utils
import { formatCreditAmount } from 'containers/financing/views/credit-limit-increase/utils/currency.utils';
// Translations
import translations from './translations/credit-change-info-card.translations';
// Types
import { CreditChangeInfoCardProps } from './types/credit-change-info-card.type';

export const CreditChangeInfoCard = ({
  currentCreditLimit,
  newCreditLimit,
  currency,
}: CreditChangeInfoCardProps): React.ReactElement => {
  const { formatMessage, formatNumber } = useCbIntl();

  return (
    <CreditChangeInfoCardSectionStyled>
      <CreditChangeInfoCardInfoStyled>
        <ParagraphComponent>
          {formatMessage({ id: translations.PREVIOUS_CREDIT_LIMIT })}
        </ParagraphComponent>
        <ParagraphComponent {...constants.FORMAT_NUMBER_CONSTANTS}>
          {formatCreditAmount({ currency, formatNumber, value: currentCreditLimit })}
        </ParagraphComponent>
      </CreditChangeInfoCardInfoStyled>
      <CreditChangeInfoCardInfoStyled>
        <ParagraphComponent>
          {formatMessage({ id: translations.NEW_CREDIT_LIMIT })}
        </ParagraphComponent>
        <ParagraphComponent {...constants.FORMAT_NUMBER_CONSTANTS}>
          {formatCreditAmount({ currency, formatNumber, value: newCreditLimit })}
        </ParagraphComponent>
      </CreditChangeInfoCardInfoStyled>
    </CreditChangeInfoCardSectionStyled>
  );
};
