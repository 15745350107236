// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  PROPS: {
    testId: 'amount-component',
  },
  TITLE_PARAGRAPH_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.L,
  },
  ASSET_PROPS: {
    id: 'info',
    colors: {
      fill: 'linkPrimaryA',
    },
  },
  AMOUNT_LABEL_VALUE: '100,00',
  INPUT_PROPS: {
    placeholder: '100,00',
    testId: 'amount-input',
    type: 'currency',
  },
  AMOUNT_ERROR_LENGTH: 34,
  AMOUNT_MULTIPLE_CALC: 0.55,
};
