// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  CONFIRMATION_AND_CUSTOMER_ACCEPTANCE_URL_SUFFIX,
  EVALUATE_NEW_BETTER_OFFER_URL_SUFFIX,
  REJECT_M2_URL_SUFFIX,
} from './constants/step-5-feedback.constants';
import { LIMIT_INCREASE_CASES_URL } from './constants/commons.constants';
// Types
import { ApprovalReturnType } from '../resolvers/types/step-5-approval-return.resolvers.type';
import { EvaluateNewOfferPropsType } from '../resolvers/types/step-5-evaluate-new-offer-props.resolvers.type';
import { EvaluateNewOfferReturnType } from '../resolvers/types/step-5-evaluate-new-offer-return.resolvers.type';

const evaluateNewOfferService = ({
  caseId,
  acceptance,
  newAmount,
}: EvaluateNewOfferPropsType): Promise<[EvaluateNewOfferReturnType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${EVALUATE_NEW_BETTER_OFFER_URL_SUFFIX}`,
    params: {
      acceptance,
      newAmount,
    },
  });

const approvalService = (caseId: string): Promise<[ApprovalReturnType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${CONFIRMATION_AND_CUSTOMER_ACCEPTANCE_URL_SUFFIX}`,
  });

const rejectM2Service = (caseId: string): Promise<[void, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${REJECT_M2_URL_SUFFIX}`,
  });

export { evaluateNewOfferService, approvalService, rejectM2Service };
